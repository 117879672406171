/* Universal Styles */

body {
  /* background-image: url("./img/eight_legs.jpg"); */
  /* background-image: url("./stars.gif"); */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat-x;
  background-color: #000000; */
  margin: 0;
  font-family: verdana, arial, helvetica, sans-serif;
  font-size: 11px;
  line-height: 1.2em;
  text-align: center;
  width: 100%;
  height: 100%;
}

#root {
  width: 1000px;
  margin: auto;
}

input {
  color: #7fff00 !important;
}
/* Nav Bar */
.navbar {
  width: 100%;
  /* overflow: hidden; */
  background-color: #333;
  padding: 0 !important;
}

#nav-row {
  width: 100%;
  margin-left: 0 !important;
}

#left-nav {
  justify-content: flex-end !important;
}

.dropdown-menu {
  background-color: blue !important;
  border-radius: 0 !important;
  margin-top: 0 !important;
}

/* .navbar_custom {
  width: 100%;
  overflow: hidden;
  background-color: #333;
} */

/* .navbar_custom a {
  float: left;
  font-size: 11px;
  color: #7fff00;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  background-color: #333;
} */

.navbar_custom a:hover,
.dropdown:hover .dropbtn {
  background-color: red;
  text-decoration: none;
}

/* .dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  background-color: blue;
} */

.navbar a {
  float: left;
  font-size: 11px;
  color: #7fff00;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  /* background-color: #333; */
  height: 100%;
}
.dropdown .dropbtn {
  font-size: 11px;
  border: none;
  outline: none;
  color: #7fff00;
  padding: 10px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: red;
}

/* .dropdown-content {
  display: none;
  position: absolute;
  background-color: #2a2a2a;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
} */

/* .dropdown-content a:hover {
  background-color: red;
} */

/* .dropdown:hover .dropdown-content {
  display: block;
} */

/* main section  */
.container {
  width: 1000px;
  margin: auto;
  justify-content: flex-start !important;
}

h1 {
  font-size: 20pt;
  text-align: center;
  font-weight: bold;
  margin: 5px;
  color: #ffffff;
}

h2 {
  font-size: 12pt;
  text-align: center;
  font-weight: bold;
  margin: 5px;
  color: #ffffff;
}

label {
  padding: 0 10px;
  background-color: #000000;
  font-size: 18px;
  color: #7fff00 !important;
  line-height: 1em;
}

form > div {
  text-align: left;
  padding: 10px 0px;
}

a {
  font-size: 10px;
  color: #7fff00 !important;
  cursor: pointer;
  text-decoration: none;
}

a.media,
a.non-media,
a.page-link,
small,
span {
  padding: 0 10px;
  /* background-color: #000000; */
  font-size: 18px;
  color: #7fff00 !important;
  line-height: 1em;
}

.speed-dial span {
  background-color: #ff000000;
}

.speed-dial button {
  background-color: #949494;
}

a:hover {
  text-decoration: underline;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.bottom span {
  font-size: 10px !important;
  background-color: transparent !important;
}

span.page-link {
  padding: 0 10px;
  background-color: #000000;
  font-size: 18px;
  color: #ff0000 !important;
  line-height: 1em;
  text-decoration: underline;
}

.visually-hidden {
  display: none;
}

.flex-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.flex-container > div {
  border: red 3px solid;
  width: 100%;
  margin: 8px;
  background-color: #2a2a2a;
}

.header {
  background-color: #2a2a2a;
  font: 11px;
}

/* logo div */
.home-logo {
  height: auto;
  float: bottom;
  width: 100%;
}

.home-logo img {
  width: 800px;
  max-height: 1000px;
  /* margin-top: 20px; */
}

.logo {
  height: 60px;
  float: left;
  width: 100%;
  /* background-color: #2a2a2a !important; */
}

.logo img {
  width: 100px;
  max-height: 80px;
  float: left;
  margin-left: 10px;
  padding: 0px;
  /* margin-top: 20px; */
}

.navbar-brand {
  padding: 0px !important;
}

div a:link,
div a:visited {
  color: #7fff00 !important;
}

button {
  -webkit-appearance: button;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark-color(buttontext, rgb(170, 170, 170));
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: -internal-light-dark-color(
    rgb(239, 239, 239),
    rgb(74, 74, 74)
  );
  box-sizing: border-box;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: -internal-light-dark-color(
    rgb(118, 118, 118),
    rgb(195, 195, 195)
  );
  border-image: initial;
}

.content {
  /* background-color: #000000; */
  margin: 10px 0px;
  box-sizing: border-box;
  overflow: auto;
}

.page-link {
  border: 0;
}

#left {
  width: 315px;
  float: left;
}

.top {
  width: 100%;
  text-align: left;
  color: #ffffff;
}
.middle {
  width: 100%;
  clear: both;
  overflow: auto;
}

#footer {
  color: #7fff00;
}

.user-banner a {
  color: #7fff00;
  font-size: 25px;
}

/* Profile */
.profile-pic {
  width: auto;
}

.profile-info {
  float: left;
  text-align: left;
  padding-left: 1.5em;

  font-size: 8pt;
  font-weight: normal;
  color: #ffffff;
}

.middle img {
  float: left;
}
#profile-pic {
  width: 130px;
  height: 120px;
}

.bottom {
  width: 100%;
  text-align: left;
  color: white;
  padding-left: 2px;
}

/* Contacts */
.contact-icon-div {
  background-color: #e0e1e2;
  padding: 10px 5px;
}

.vplayer {
  width: 100%;
  height: auto;
}
#right {
  width: 600px;
  float: right;
}

/* Friends */
#friends-text {
  padding-left: 5px;
}
#friends-pics {
  justify-content: center;
  padding: 0 5px;
}
#friends-pics img {
  padding: 10px;
  height: 80px;
  width: 80px;
}
#friends-pics div {
  border: none;
  width: auto;
  margin: 0;
}
#friends-bottom {
  text-align: right;
}

#friends-bottom div {
  padding: 20px;
}
#intrests {
  padding: 5px;
}
#intrests div {
  width: 35%;
  float: left;
  text-align: left;
  color: white;
}

#intrests b {
  color: #b2dfee !important;
  font-size: 8pt;
  font-weight: bold;
}

.instagram-media {
  /* height: 200px; */
}
iframe {
  margin: 0px !important;
  border: none !important;
  border-radius: 0 !important;
  max-width: none !important;
  width: 100% !important;
  padding-bottom: 5px !important;
  background-color: none !important;
}
blockquote {
  margin: 0px !important;
  border: none !important;
  border-radius: 0 !important;
  max-width: none !important;
  width: 100% !important;
  padding-bottom: 5px !important;
  background-color: none !important;
}

.media {
  display: none;
}
@media screen and (max-width: 800px) {
  #root {
    width: 300px;
    margin: auto;
  }

  .text-center {
    padding-left: 10px;
    padding-right: 10px;
  }

  .home-logo {
    padding-top: 9rem;
  }

  .home-logo img {
    width: 350px;
    max-height: 1000px;
  }

  .navbar_custom a {
    padding: 14px 9px !important;
  }

  body {
    background-attachment: scroll;
  }
  .content {
    width: 96%;
    margin: auto;
  }
  .container {
    width: auto;
  }
  #left,
  #right {
    width: 100%; /* The width is 100%, when the viewport is 800px or smaller */
    float: none;
  }

  .navbar a {
    padding: 14px 6px;
  }

  .dropdown .dropbtn {
    padding: 14px 5px;
  }

  .non-media {
    display: none;
  }

  #media-content {
    display: flex;
    flex-direction: column;
  }
  .media {
    display: flex;
    justify-content: center;
  }

  .top {
    text-align: center;
  }
  .middle {
    width: 70%;
    margin: auto;
  }
  .middle img {
    float: none;
    margin: 10px 0;
  }
  .profile-info {
    text-align: center;
    float: none;
    padding: 0;
  }
  .bottom {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  #friends-pics {
    width: 100%;
  }

  .left-textbox {
    text-align: left !important;
    float: left !important;
  }

  .right-textbox {
    text-align: right !important;
    float: right !important;
  }
}
